<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Historias Clínicas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Historias</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>LISTADO DE HISTORIAS CLÍNICAS </strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="corper"><strong>CÓDIGO PACIENTE: </strong></label>
            <InputText
              v-model="cliente_id"
              placeholder="Ingrese Corper"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="cliente"><strong>BUSCAR PACIENTE:</strong></label>
            <div class="p-inputgroup">
              <AutoComplete
                field="nombre"
                v-model="clienteSelect"
                :suggestions="clientes"
                placeholder="Buscar Cliente"
                v-tooltip.top="'Buscar Todos los Pacientes'"
                class="mr-1"
                @keyup.enter="buscarCliente($event)"
              >
                <template #item="slotProps">
                  <div>
                    <strong>{{ slotProps.item.nombre }}</strong>
                  </div>
                </template>
              </AutoComplete>
              <Button
                icon="pi pi-search"
                class="p-button-rounded p-button-info p-button-outlined mr-1"
                v-tooltip.top="'Buscar Cliente'"
                @click="buscarCliente($event)"
              />
            </div>
          </div>
          <div class="field col-12 md:col-2">
            <label for="ci_nit"><strong>CI/NIT: </strong></label>
            <InputText
              v-model="ci_nit"
              placeholder="Ingrese CI/NIT"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="acciones"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Buscar Productos'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="dt_historias"
          key="id"
          :value="historia_clientes"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Historias..."
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="id" header="CORPER"></Column>
          <Column field="nombre" header="NOMBRE COMPLETO">
            <template #body="{ data }">
              <div class="flex flex-column">
                {{ data.nombre }}
              </div>
            </template>
          </Column>
          <Column field="ci_nit" header="CI/NIT"></Column>
          <Column field="sexo" header="SEXO"></Column>
          <Column field="telefeno" header="TELÉFONO">
            <template #body="{ data }">
              <div class="flex flex-column">
                {{ data.telefono }}
              </div>
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span
                class="product-badge status"
                :class="{
                  'status-outofstock': data.estado !== 1,
                  'status-instock': data.estado === 1,
                }"
              >
                {{ textoEstado(data.estado) }}
              </span>
            </template>
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                class="p-button-icon-only p-button-raised"
                icon="pi pi-ellipsis-v"
                v-tooltip.top="'Ver Acciones'"
                @click="$refs['menu' + slotProps.data.id].toggle($event)"
              />
              <Menu
                :ref="'menu' + slotProps.data.id"
                :popup="true"
                :model="acciones(slotProps.data)"
              >
              </Menu>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import ClienteService from "@/service/ClienteService";

export default {
  data() {
    return {
      enviando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      cliente_id: null,
      ci_nit: null,
      clienteSelect: null,
      clientes: [],
      historia_clientes: [],
      clientesListadoFiltrado: [],
      claseEstado: ["status-outofstock", "status-instock", "status-outofstock"],
    };
  },
  auth: null,
  clienteService: null,

  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.CargarHistoriasClientes();
  },
  methods: {
    acciones(data) {
      return [
        {
          label: "Ver o Crear Historia",
          icon: "pi pi-fw pi-eye",
          /* disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true, */
          visible: data.estado == 1 ? true : false,
          to: `/historias/${data.id}/detalle`,
        },
      ];
    },
    buscarCliente() {
      let datos = {
        texto: this.clienteSelect ?? null,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 3000,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    BuscarFiltro() {
      if (!this.clienteSelect && !this.ci_nit && !this.cliente_id) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Debe ingresar un filtro",
          life: 3000,
        });
        return;
      }
      this.historia_clientes = [];
      this.enviando = true;
      this.CargarHistoriasClientes();
    },
    CargarHistoriasClientes() {
      let datos = {
        id: this.cliente_id ?? null,
        ci_nit: this.ci_nit ?? null,
        cliente: this.clienteSelect ? this.clienteSelect.nombre : null,
      };
      this.clienteService
        .buscarHistorias(datos)
        .then((response) => {
          this.historia_clientes = response.historia_clientes;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "INACTIVO";
        case 1:
          return "ACTIVO";
        case 2:
          return "OBITADO";
        default:
          return "SIN ESTADO";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
}
.p-invalid {
  color: red;
}
</style>